import {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';
import routes from '~react-pages';

function App() {
  // 로더 스타일 정의
  const loaderStyle = {
    border: '20px solid #f3f3f3', // Light grey
    borderTop: '20px solid #543de8', // Purple
    borderRadius: '50%',
    width: '150px',
    height: '150px',
    animation: 'spin 1s linear infinite'
  };

  // 로더 컨테이너 스타일
  const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // 전체 뷰포트 높이
    width: '100vw' // 전체 뷰포트 너비
  };

  // @keyframes 정의를 위한 글로벌 스타일 적용
  const globalStyles = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <>
      <style>{globalStyles}</style>
      <Suspense
        fallback={
          <div style={loaderContainerStyle}>
            <div style={loaderStyle}></div>
          </div>
        }
      >
        {useRoutes(routes)}
      </Suspense>
    </>
  );
}

export default App;
