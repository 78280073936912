import {useState} from 'react';
import {FaChevronDown, FaChevronUp} from 'react-icons/fa6';
import styles from '../../styles/pages/index.module.scss';

const questionItems = [
  {
    num: '01',
    q: '문자 발송 단가는 조정되나요?',
    a: '발송량에 따라 지원합니다. 실시간 1:1 문의로 연락 주세요.'
  },
  {
    num: '02',
    q: '문자 발송 실패 시 충전금 환급 처리 되나요?',
    a: '해외문자 발송은 문자 발송 시, 문자 발송 비용이 자동 차감됩니다. 발송 품질을 위해 다양한 노력을 하고 있어 성공률 99%를 위해 최선을 다하겠습니다.'
  },
  {
    num: '03',
    q: '3사 스팸 테스트가 가능한가요?',
    a: '사이트 내, 해당 기능을 구축하였습니다. 자유롭게 테스트 가능합니다. 성공율 높은 문구를 세팅하시고, 임시저장 기능 및 예약 기능을 이용하여 타이밍에 맞는 문자를 발송하세요.'
  },
  {
    num: '04',
    q: '문자 발송 시 몇 건씩 발송하는게 좋은가요?',
    a: '제한은 없으나, 콘서트에서 한 줄로 길게 늘어선 것보다, 두세 줄로 입장하면 빠른 이치입니다. 병렬 구조로 발송하기 위해 2~3천건 내로 나누어 발송하시면 발송율이 더 좋아집니다.'
  },
  {
    num: '05',
    q: '장문문자 발송(MMS, LMS)이 되나요?',
    a: '해외 통신사에서 지원하지 못하는 기능입니다. 단문문자(SMS)로 한글 70자까지만 발송 가능합니다.'
  },
  {
    num: '06',
    q: '자체 문자 발송 플랫폼이나 IT컨설팅 문의하고 싶어요.',
    a: 'IT컨설팅 및 플랫폼 분양을 지원합니다. 실시간 1:1문의로 연락주세요.'
  }
];

const Questions = () => {
  const [isOpen, setIsOpen] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setIsOpen(isOpen === index ? null : index);
  };

  return (
    <div className={`is-fullwidth ${styles.questions__box}`}>
      {questionItems.map((item, index) => (
        <div key={index} onClick={() => toggleQuestion(index)}>
          <ul>
            <li>
              <p>{item.num}</p>
              <p>{item.q}</p>
              {isOpen === index ? (
                <p>
                  <FaChevronUp />
                </p>
              ) : (
                <p>
                  <FaChevronDown />
                </p>
              )}
            </li>
            {isOpen === index && <li className={`is-fullwidth ${styles.answer}`}>{item.a}</li>}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Questions;
