import React from 'react';

interface CardHeaderProps {
  children: React.ReactNode;
  description?: string;
}
const CardHeader: React.FC<CardHeaderProps> = ({children, description}) => {
  return (
    <header className="card-header is-flex is-flex-direction-column">
      <p className="card-header-title has-text-centered is-justify-content-center py-4 is-size-3">{children}</p>
      {description && <p className="has-text-centered subtitle px-6">{description}</p>}
    </header>
  );
};

interface CardContentProps {
  children: React.ReactNode;
}
const CardContent: React.FC<CardContentProps> = ({children}) => {
  return <div className="card-content">{children}</div>;
};

interface CardFooterProps {
  children: React.ReactNode;
}
/**
 * Each first herichy child will be added the class card-footer-item
 * @param children DOM children of the elements
 * @returns React.DOM
 */
const CardFooter: React.FC<CardFooterProps> = ({children}) => {
  return (
    <footer className="card-footer">
      {React.Children.map(children, (child, index) => {
        return (
          <div key={index} className="card-footer-item">
            {child}
          </div>
        );
      })}
    </footer>
  );
};

interface props {
  children: React.ReactNode;
  className?: string;
}
const Card: React.FC<props> & {Header: React.FC<CardHeaderProps>; Content: React.FC<CardContentProps>; Footer: React.FC<CardFooterProps>} = ({children, className}) => {
  return <div className={`card p-5 ${className}`}>{children}</div>;
};

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Footer = CardFooter;

export default Card;
