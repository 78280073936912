/* eslint-disable no-unused-vars */
import {create} from 'zustand';
import {MenuCategory} from '../models/Menu';

interface MenuStore {
  active: boolean;
  isOpen: boolean;
  pathname?: string;
  menuList: MenuCategory[];
  setActive: (active: boolean) => void;
  setIsOpen: (isOpen: boolean) => void;
  setPathname: (pathname: string) => void;
  setMenuList: (menuList: MenuCategory[]) => void;
}
const useMenu = create<MenuStore>((set) => ({
  active: false,
  isOpen: false,
  pathname: '',
  menuList: [],
  setActive: (active: boolean) => {
    set({active});
  },
  setIsOpen: (isOpen: boolean) => {
    set({isOpen});
  },
  setPathname: (pathname: string) => set({pathname}),
  setMenuList: (menuList: MenuCategory[]) => set({menuList})
}));

export default useMenu;
