import styles from '../../styles/pages/index.module.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import {useEffect, useState} from 'react';

// Swiper 모듈 사용 설정
const modules = [Navigation, Pagination, Autoplay];

const slides = [
  {
    image: '/mainTelephone1.png',
    mobileImage: '/mobile/mainTelephone1-mobile.png',
    title: '쎈 성능!',
    subtitle1: '해외 통신사 전속계약!',
    subtitle2: '문자 발송 성공률 99%',
    buttonMessage: '문자 발송 가격 문의'
  },
  {
    image: '/mainTelephone2.png',
    mobileImage: '/mobile/mainTelephone2-mobile.png',
    title: '쎈! 보안',
    subtitle1: '보안 강화 스펙 구성!',
    subtitle2: '서버, 방화벽, 웹방화벽 구성',
    buttonMessage: '텔레그램 문의'
  },
  {
    image: '/mainTelephone3.png',
    mobileImage: '/mobile/mainTelephone3-mobile.png',
    title: '쎈 가격!',
    subtitle1: '발신량에 따른 가격 경쟁력!',
    subtitle2: '마케팅 확장은 지니문자에서',
    buttonMessage: '텔레그램 문의'
  },
  {
    image: '/mainTelephone4.png',
    mobileImage: '/mobile/mainTelephone4-mobile.png',
    title: '쎈! 컨설팅',
    subtitle1: '플랫폼 구축, 비지니스 개선 문의',
    subtitle2: 'IT 고민 해결',
    buttonMessage: '텔레그램 문의'
  }
];

const PhotoSlide = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 426);
    };

    handleResize(); // 초기 실행
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Swiper modules={modules} pagination={{clickable: true}} autoplay={{delay: 5000}} className="swiperContainer">
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <img src={isMobile ? slide.mobileImage : slide.image} alt={slide.title} />
          <div className={`is-fullwidth ${styles.imageSlider__box}`}>
            <ul>
              <li>{slide.title}</li>
              <li>{slide.subtitle1}</li>
              <li>{slide.subtitle2}</li>
            </ul>
            <button className="button-primary">{slide.buttonMessage}</button>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PhotoSlide;
