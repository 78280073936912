import {MenuCategory} from '@genie-front/shared/models/Menu';
import useMenu from '@genie-front/shared/stores/menu.store';
import {useEffect} from 'react';
import {FaRegFileLines} from 'react-icons/fa6';
import {RiChatCheckLine, RiChat4Line, RiMailLine, RiUser2Line, RiMessage2Line} from 'react-icons/ri';
import {useLocation} from 'react-router';
import cropIcon from '../assets/crop.svg';
import newMessageIcon from '../assets/newMessage.svg';

function RouteChangeListener() {
  const location = useLocation();
  const {setMenuList, setActive, setPathname} = useMenu();
  const menuList: MenuCategory[] = [
    {
      name: '문자 보내기',
      items: [
        {
          name: '문자 전송',
          link: '/dashboard',
          Icon: newMessageIcon
        },
        {
          name: '3사 테스트',
          link: '/dashboard/sms/test',
          Icon: <RiMessage2Line />
        },
        {
          name: '문자 전송내역',
          link: '/dashboard/sms/history',
          Icon: <RiChatCheckLine />
        }
      ]
    },
    {
      name: '문자 관리',
      items: [
        {
          name: '문자 보관함',
          link: '/dashboard/sms/box',
          Icon: <RiChat4Line />
        },
        {
          name: '주소록',
          link: '/dashboard/sms/address-book',
          Icon: <FaRegFileLines />
        }
        // {
        //   name: '발신번호',
        //   link: '/dashboard/sms/sender-number',
        //   Icon: <RiPhoneLine />
        // }
      ]
    },
    {
      name: '결제 관리',
      items: [
        {
          name: '금액 충전',
          link: '/dashboard/payment/charge',
          Icon: cropIcon
        },
        {
          name: '충전 내역',
          link: '/dashboard/payment/history',
          Icon: cropIcon
        }
      ]
    },
    {
      name: '고객센터',
      items: [
        {
          name: '1:1문의',
          link: '/dashboard/customer/inquiry',
          Icon: <RiMailLine />
        },
        {
          name: '마이페이지',
          link: '/dashboard/customer',
          Icon: <RiUser2Line />
        }
      ]
    }
  ];

  useEffect(() => {
    setMenuList(menuList);
    setActive(false);
  }, []);

  useEffect(() => {
    setPathname(location.pathname);

    if (location.pathname.includes('dashboard')) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location]);

  return null;
}

export default RouteChangeListener;
