import useModal from '../../stores/modal.store';
import {FaXmark} from 'react-icons/fa6';
import styles from '../../styles/modal/modal.module.scss';

const Modal: React.FC = () => {
  const {open, element, title, buttons, isCard, width, setOpen, setIsCard} = useModal();

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      {isCard ? (
        <div className={`modal-card ${styles.modalCard}`} style={{width: width, borderRadius: '0'}}>
          <header className="modal-card-head has-background-primary" style={{borderRadius: '0', height: '40px'}}>
            <p className="modal-card-title" style={{fontWeight: 'bold'}}>
              {title}
            </p>
            <button
              className="card-header-icon"
              aria-label="close"
              onClick={() => {
                setOpen(false);
                setIsCard(false);
              }}
            >
              <span className="icon">
                <FaXmark className="is-size-1" />
              </span>
            </button>
          </header>
          <section className="modal-card-body has-background-white">{element}</section>
          {buttons.length > 0 && (
            <footer className="modal-card-foot has-background-white" style={{borderRadius: '0', height: '80px', borderTop: '1px solid '}}>
              <div className="buttons has-fullwidth is-flex is-justify-content-center">
                {buttons.map((button, index) => (
                  <button
                    key={index}
                    style={{width: '30%', height: '40px'}}
                    className={`button ${button.colorSchema || 'is-primary'}`}
                    onClick={() => {
                      button.onClick();
                    }}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
            </footer>
          )}
        </div>
      ) : (
        <>
          <div className="modal-content">{element}</div>
          <button className="modal-close is-large" aria-label="close" onClick={() => setOpen(false)}></button>
        </>
      )}
    </div>
  );
};

export default Modal;
