import {useEffect, useState} from 'react';
import styles from '../styles/pages/index.module.scss';
import PhotoSlide from './home/photoSlide';
import Questions from './home/questions';

const slide = {
  image: '/signinfo.svg',
  mobileImage: '/mobile/signinfo-mobile.svg',
  alt: 'signinfo'
};

const IndexPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 426);
    };

    handleResize(); // 초기 실행
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`is-fullwidth ${styles.mainPageSection}`}>
      <section className={`is-fullwidth ${styles.imageSlider}`}>
        <PhotoSlide />
      </section>
      <section className={`is-fullwidth ${styles.enforce}`}>
        <p>성능 강화!&nbsp;&nbsp;보안 강화!&nbsp;&nbsp;가격 강화!</p>
        <div className={`is-fullwidth ${styles.enforce__box}`}>
          <div>
            <img src="/icons/noads.png" />
            <ul>
              <li>010 번호 인증 X</li>
              <li>광고 의무 표기가 필요 없어요!</li>
            </ul>
          </div>
          <div>
            <img src="/icons/megaphone.png" />
            <ul>
              <li>익명성 보장</li>
              <li>365일 24시간 제한 없이 발송 가능!</li>
            </ul>
          </div>
        </div>
        <ul className={`is-fullwidth ${styles.workingTime}`}>
          <li>충전 업무 시간에 문의하시면 더욱 편리하게 해외문자를 이용하실 수 있습니다.</li>
          <li>업무시간:&nbsp;&nbsp;10:00~23:00</li>
        </ul>
      </section>
      <hr />
      <section className={`is-fullwidth ${styles.basics}`}>
        <p>탄탄한 기본기</p>
        <div className={`is-fullwidth ${styles.basics__box}`}>
          <div>
            <ul>
              <li>해외 통신사 전속 계약</li>
              <li>지니문자는 문자발송의 성공률을 높이고,</li>
              <li>단가를 낮추고자 업무협업을 맺고</li>
              <li>해외 통신사와 협업합니다.</li>
            </ul>
            <img src="/icons/globe.png" />
          </div>
          <div>
            <ul>
              <li>스팸 걱정 없는 해외 문자</li>
              <li>(광고) 표기와 080 수신 거부 번호 작성 의무가</li>
              <li>없습니다.</li>
            </ul>
            <img src="/icons/greencheck.png" />
          </div>
          <div>
            <ul>
              <li>통신 3사 테스트 기능</li>
              <li>문자 발송 문구의 스팸 여부를 점검하는 기능을</li>
              <li>구축했습니다. 실패율 최소를 위해 문구 점검 후,</li>
              <li>문자 마케팅을 시작합니다.</li>
            </ul>
            <img src="/icons/pencil.png" />
          </div>
          <div>
            <ul>
              <li>편리한 기능 활용</li>
              <li>주소록 업로드, 문자 저장, 예약 발송 등의 기능을</li>
              <li>활용합니다.</li>
            </ul>
            <img src="/icons/lightning.png" />
          </div>
        </div>
      </section>
      <section className={`is-fullwidth ${styles.signinInformation}`}>
        <p>지니문자 가입 안내</p>
        <div className={`is-fullwidth ${styles.signinInformation__box}`}>
          <div>
            <img src="/icons/message.png" alt="page" unselectable="on" />
            <ul>
              <li>텔레그램 상담</li>
              <li>
                텔레그램 아이디 <span>@telegram</span>을 통해 문의
              </li>
              <li>(발송업종, 1주일 문자 발송량 상담 후 단가 협의를 진행합니다.)</li>
            </ul>
          </div>
          <img src={isMobile ? slide.mobileImage : slide.image} alt={slide.alt} unselectable="on" className={`${styles.signInfoImg}`} />
        </div>
      </section>
      <section className={`is-fullwidth ${styles.sales}`}>
        <img src="/sellbackground.png" alt="page" unselectable="on" />
        <div className={`is-fullwidth ${styles.sales__box}`}>
          <div>
            <ul>
              <li>해외문자 사이트 분양</li>
              <li>문자 사이트를 운영해보고 싶으시다면 연락해주세요.</li>
              <li>솔루션부터 보안 해외통신사 계약 체결까지 모든 것을 한 방에 해결해드립니다.</li>
              <li>문자 사이트 솔루션은 물론, 해외 통신사 계약 홍보 방식 등</li>
              <li>모든 노하우를 전수해드립니다.</li>
              <li>
                더 자세한 사항은&nbsp;
                <span>@telegram</span>
                으로 연락해주세요.
              </li>
            </ul>
            <button className="button-secundary">텔레그램 문의</button>
          </div>
          <img src="/icons/key.png" alt="page" unselectable="on" className={styles.sales__box__img} />
        </div>
      </section>
      <section className={`is-fullwidth ${styles.questions}`}>
        <p>무엇이든 물어보세요</p>
        <Questions></Questions>
      </section>
    </div>
  );
};

export default IndexPage;
