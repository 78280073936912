import {useState} from 'react';
import styles from '../../styles/Header.module.scss';
import useAuth from '../../stores/authStore';
import {FaArrowRightFromBracket} from 'react-icons/fa6';
import {useNavigate} from 'react-router-dom';

const Header = () => {
  const [isActive, setisActive] = useState(false);
  const {auth, logout} = useAuth();
  const navigate = useNavigate();

  const disconnect = () => {
    logout();
    navigate('/signin');
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className={`navbar-brand ${styles.brand}`}>
        <a className={`navbar-item has-text-primary is-size-5 ${styles.brand}`} href="/">
          지니문자
        </a>

        <a role="button" onClick={() => setisActive(!isActive)} className={`navbar-burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
        <div className="navbar-end">
          {auth ? (
            <>
              <h5 className="has-text-black is-flex is-align-items-center mr-4">
                안녕하세요! <span className="has-text-primary ml-2">{auth.name}</span>님
              </h5>

              {/* <div className="navbar-item">
                <Link to={'/dashboard/customer'} className="has-text-gray">
                  <span className="icon mr-1 has-text-gray">
                    <FaUser />
                  </span>
                  마이페이지
                </Link>
              </div> */}
              <div className="navbar-item has-text-gray cursor" onClick={() => disconnect()}>
                <span className="icon mr-1">
                  <FaArrowRightFromBracket />
                </span>
                로그아웃
              </div>
            </>
          ) : (
            <>
              <div className="navbar-item">
                <a href="/" className="is-size-6">
                  문자서비스안내
                </a>
              </div>
              <div className="navbar-item">
                <a href="/" className="is-size-6">
                  비용안내
                </a>
              </div>
              <div className="navbar-item">
                <div className="buttons">
                  <a className={`button is-primary ${styles.button}`} href="/signin">
                    로그인
                  </a>
                  <a className={`button is-light ${styles.button}`} href="/subscribe">
                    회원가입
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
