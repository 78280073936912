import {create} from 'zustand';
import {persist} from 'zustand/middleware';

interface WorkerState {
  jobId: string | null;
  // eslint-disable-next-line no-unused-vars
  setJobId: (id: string | null) => void;
}
export const useWorkerStore = create(
  persist<WorkerState>(
    (set) => ({
      jobId: null,
      setJobId: (id) => set({jobId: id})
    }),
    {
      name: 'worker-job', // unique name for the storage item
      getStorage: () => localStorage // (optional) by default the 'localStorage' is used
    }
  )
);
