import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';
import {Auth} from '../models/auth';

interface AuthStore {
  auth: Auth | null;
  // eslint-disable-next-line no-unused-vars
  setAuth: (auth: Auth) => void;
  logout: () => void;
}
const useAuth = create<AuthStore>()(
  persist(
    (set) => ({
      auth: null,
      setAuth: (auth) => set(() => ({auth})),
      logout: () => set(() => ({auth: null}))
    }),
    {
      storage: createJSONStorage(() => localStorage),
      name: 'session'
    }
  )
);

export default useAuth;
