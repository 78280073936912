import {createContext, useContext, useEffect} from 'react';
import useAuth from '@genie-front/shared/stores/authStore';
import {useMatch, useNavigate} from 'react-router';

const AuthContext = createContext({});

interface props {
  children: React.ReactNode;
}
export const AuthProvider: React.FC<props> = ({children}) => {
  const {auth, setAuth, logout} = useAuth();
  const navigate = useNavigate();
  const protectedRoutes = useMatch('/dashboard/*');

  useEffect(() => {
    const checkAuth = () => {
      if (!auth) {
        if (protectedRoutes) navigate('/signin');

        return;
      }
      if (auth.exp < Math.floor(Date.now() / 1000)) {
        logout();
        navigate('/signin');
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    if (!auth && protectedRoutes) {
      logout();
      navigate('/signin');
    } else if (auth && !protectedRoutes) {
      navigate('/dashboard');
    }
  }, [protectedRoutes, auth]);

  return <AuthContext.Provider value={{auth, setAuth, logout}}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
