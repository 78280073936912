/* eslint-disable no-unused-vars */
import {create} from 'zustand';

interface ModalStore {
  open: boolean;
  element: React.ReactNode | JSX.Element | null;
  isCard: boolean;
  title: string;
  buttons: {label: string; colorSchema?: 'button-primary' | 'button-secundary' | 'button-cancel'; onClick: () => unknown}[];
  width: string;
  closeFuntion: (() => unknown) | null;

  setOpen: (open: boolean) => void;
  onClose: (callback: () => unknown) => void;
  setContent: (elements: React.ReactNode) => void;
  setIsCard: (isCard: boolean) => void;
  setTitle: (title: string) => void;
  setWidth: (width: string) => void;
  setButtons: (buttons: {label: string; colorSchema?: 'button-primary' | 'button-secundary' | 'button-cancel'; onClick: () => unknown}[]) => void;
}
const useModal = create<ModalStore>((set) => ({
  open: false,
  closeFuntion: null,
  element: null,
  isCard: false,
  title: '',
  width: 'auto',
  buttons: [],

  setOpen: (open: boolean) => set({open}),
  onClose: (callback: () => unknown) => set({closeFuntion: callback}),
  setContent: (elements: React.ReactNode) => set({element: elements}),
  setIsCard: (isCard: boolean) => set({isCard}),
  setTitle: (title: string) => set({title}),
  setWidth: (width: string) => set({width}),
  setButtons: (buttons: {label: string; colorSchema?: 'button-primary' | 'button-secundary' | 'button-cancel'; onClick: () => unknown}[]) => set({buttons})
}));

export default useModal;
