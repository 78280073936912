import styles from '../../styles/Footer.module.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={`footer ${styles.footerContainer}`}>
      <div className={`has-text-centered ${styles.linksContainer}`}>
        <a href="/">회사소개</a>
        <a href="/">서비스 소개</a>
        <a href="/">고객센터</a>
        <a href="/termsOfUse">이용약관</a>
        <a href="/privacyPolicy">개인정보처리방침</a>
      </div>
      <div className={`has-text-centered ${styles.linksContainer}`}>
        <p>메디로움&nbsp;&nbsp;|&nbsp;&nbsp;서울특별시 강남구 삼성로 212, 31동 413호(대치동, 은마아파트)</p>
        <p>사업자등록번호: 810-16-00960&nbsp;&nbsp;|&nbsp;&nbsp;통신판매업신고: 제0000-00000호&nbsp;&nbsp;|&nbsp;&nbsp;</p>
        <p>
          사업자: 김지은&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="mailto:genie7sms@gmail.com">이메일: genie7sms@gmail.com</a>
        </p>
        <p>© {currentYear} genie-sms. All right reserves.</p>
      </div>
    </footer>
  );
};

export default Footer;
