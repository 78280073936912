import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './styles/index.scss';
import {BrowserRouter} from 'react-router-dom';
import {Footer, Header, Menu, Modal} from '@genie-front/shared/index';
import useMenu from '@genie-front/shared/stores/menu.store';
import RouteChangeListener from './utils/listener.tsx';
import {AuthProvider} from './context/AuthContext.tsx';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useWorkerStore} from './store/useWorkerStore.ts';

function Main() {
  const {menuList, active, pathname} = useMenu();
  const {jobId, setJobId} = useWorkerStore();
  const workerRef = useRef<Worker | null>(null);
  const baseURL = (import.meta.env.VITE_BACKEND_URL || 'http://localhost:3000') + '/api/v1';

  useEffect(() => {
    if (window.Worker) {
      workerRef.current = new Worker(new URL('./worker/ExcelWatcherWorker.ts', import.meta.url), {
        type: 'module'
      });

      workerRef.current.onmessage = function (e) {
        if (e.data.data.state === 'completed') {
          const data = e.data.data.data;
          // `${data.jsonData.length}번의 성공과 ${data.failedItems.length}번의 실패로 가져온 연락처`

          toast.success(`${data.jsonData.length}번의 성공, ${data.failedItems.length}번의 실패, ${data.duplicates.length}번의 복사본으로 가져온 연락처`, {
            onClick: () => {
              window.location.href = '/dashboard/sms/address-book';
            },
            closeButton: ({closeToast}) => (
              <i className="pl-2" style={{width: '50px'}} onClick={closeToast}>
                이동
              </i>
            )
          });

          setJobId(null);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (workerRef.current && jobId) {
      workerRef.current.postMessage({type: 'start', data: {id: jobId, apiBaseUrl: baseURL}});
    } else {
      workerRef.current?.postMessage({type: 'stop'});
    }
  }, [jobId]);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <RouteChangeListener />
          <Modal />
          <ToastContainer newestOnTop />
          <Header />
          <div className={`main ${active ? 'has-side-menu' : ''}`}>
            {active && <Menu activeUrl={pathname} list={menuList} />}
            <App />
          </div>
          {!active && <Footer />}
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById('root')!).render(<Main />);
