import {ReactSVG} from 'react-svg';
import {MenuCategory} from '../../models/Menu';
import styles from '../../styles/Menu.module.scss';
import useMenu from '../../stores/menu.store';

interface MenuProps {
  activeUrl?: string;
  list: MenuCategory[];
}
const Menu: React.FC<MenuProps> = ({list, activeUrl = ''}) => {
  const {isOpen, setIsOpen} = useMenu();

  return (
    <aside className={`menu ${styles.menu} ${isOpen ? styles.menuOpen : ''}`}>
      <div className={styles.actionMenu} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? '<' : '>'}
      </div>
      {list.map((category, index) => (
        <div key={index}>
          <p className="menu-label">{category.name}</p>
          <ul className="menu-list">
            {category.items.map((item, index) => {
              return (
                <li key={index}>
                  <a href={item.link} className={`${activeUrl === item.link ? styles['is-active'] : ''}`}>
                    <span className="icon mr-2">{item.Icon && typeof item.Icon === 'function' ? <item.Icon /> : typeof item.Icon === 'string' ? <ReactSVG src={item.Icon} /> : item.Icon}</span>
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </aside>
  );
};

export default Menu;
